import React, { FC } from 'react'
import { graphql, Link } from 'gatsby'

import {
  Frontmatter,
  TemplateProps
} from './template-types'

import {
  Breadcrumb,
  BreadcrumbItem,
  PageTitle
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import TemplateContentRow from '../components/template-content-row'


const UpcomingProgramsTemplate: FC<TemplateProps> = ({ data }: TemplateProps) => {
  const html: unknown = data.mdx.body
  const program: Frontmatter = data.mdx.frontmatter

  return (
    <React.Fragment>
      <SEO
        title={program.metaTitle}
        canonicalUrl={program.canonicalUrl}
        description={program.metaDescription}
        lang={program.seoLang}
        ogDescription={program.ogDescription}
        ogImage={program.ogImage}
        ogImageAlt={program.ogImageAlt}
      />
      <Layout>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/upcoming-programs/'>Upcoming Programs</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            {program.title}
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>{program.title}</PageTitle>
        <TemplateContentRow resource={ program } html={ html } />
      </Layout>
    </React.Fragment>
  )
}

export default UpcomingProgramsTemplate

export const query = graphql`
  query($nodeId: String!) {
    mdx(id: {eq: $nodeId}) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        additionalTags
        canonicalUrl
        metaTitle
        metaDescription
        ISOLang
        ogDescription
        ogImage
        ogImageAlt
        sidebarCards {
          cardText
          cardTitle
          contacts {
            infoat
            person
          }
          form {
            sendTo
            type
          }
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          imageAlt
          urls {
            buttonText
            external
            url
            disabled
          }
        }
        title
        type
        snipcart {
          # button {
          #   className
          #   description
          #   id
          #   max_quantity
          #   metadata {
          #     sendto
          #   }
          #   min_quantity
          #   name
          #   price
          #   quantity
          #   shippable
          #   disabled
          #   buttonText
          # }
          options {
            option
            buttonText
            description
            id
            max_quantity
            metadata {
              sendto
            }
            min_quantity
            name
            price
            quantity
            shippable
          }
        }
      }
    }
  }
`
